<template>
  <div>
    <v-snackbar v-model="showSnackBar" color="#00a972" right :timeout="timeout">
      {{msg}}
      <v-btn text @click="showSnackBar = false">
        <v-icon style="color:white;">mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <div
      id="loadingmessage"
      style="text-align:center;margin: 0px; padding: 0px; position: fixed; right: 0px; top: 0px; width: 100%; height: 100%; background-color: white; z-index: 30001; opacity: 0.8;"
      v-if="appLoading"
    >
      <p style="position: absolute; color: White; top: 40%; left: 50%;">
        <img src="../../assets/loader.gif" />
      </p>
    </div>
    <v-layout px-2 py-4 wrap justify-center>
      <v-flex xs12 sm12 md11>
        <v-card>
          <v-layout pt-4 wrap justify-space-around>
            <v-flex xs12 sm12 md12 text-center>
              <span style="font-size:20px;font-weight:500;">Add Blog</span>
            </v-flex>
          </v-layout>
          <v-layout pa-4 wrap justify-space-around>
            <v-flex xs12 sm12 md12>
              <v-text-field v-model="title" color="#26af82" label="Title"></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md12>
              <v-textarea
                v-model="shortDescription"
                rows="2"
                color="#26af82"
                label="Short Description"
              ></v-textarea>
            </v-flex>
            <v-flex xs12 sm12 md12>
              <label>Description</label>
              <vue-editor
                v-model="description"
                ref="description"
                prepend-icon="mdi-format-align-justify"
                label="Description"
                color="#26af82"
              ></vue-editor>
            </v-flex>
            <v-flex xs12 sm12 md12>
              <v-combobox
                :autocomplete="false"
                v-model="tag"
                :items="tags"
                label="Tag"
                multiple
                color="#26af82"
                item-color="#26af82"
                dense
                chips
                required
              ></v-combobox>
            </v-flex>
            <v-flex xs12 sm12 md12>
              <v-select
                :autocomplete="false"
                v-model="category"
                :items="categories"
                label="Category"
                item-text="name"
                item-value="_id"
                multiple
                color="#26af82"
                item-color="#26af82"
                dense
                chips
                required
              ></v-select>
              <!-- <v-combobox
                :autocomplete="false"
                v-model="category"
                :items="categories"
                label="Category"
                item-text="name"
                item-value="name"
                multiple
                color="#26af82"
                item-color="#26af82"
                dense
                chips
                required
              ></v-combobox>-->
            </v-flex>

            <v-flex xs12 sm12 md12>
              <v-text-field v-model="author" color="#26af82" label="Author"></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md4 px-2>
              <v-select
                v-model="type"
                :items="types"
                color="#26af82"
                item-color="#26af82"
                label="Blog Type"
              ></v-select>
            </v-flex>
            <v-flex xs12 sm12 md4 px-2>
              <label for="media">Media</label>
              <input ref="media" type="file" accept="*" id="media" required @change="onFileChanged" />
            </v-flex>
            <v-flex v-if="type!='Normal Blog'" xs12 sm12 md4 px-2>
              <label for="thumbnail">Thumbnail</label>
              <input
                ref="thumbnail"
                type="file"
                accept="*"
                id="thumbnail"
                required
                @change="onFileChanged2"
              />
            </v-flex>
          </v-layout>
          <v-layout pb-4 wrap justify-center>
            <v-flex xs12 sm12 md3 px-2>
              <v-btn
                small
                dark
                color="red"
                text
                block
                class="text-capitalize"
                @click="addDraft"
              >Save As Draft</v-btn>
            </v-flex>
            <v-flex xs12 sm12 md4 px-2>
              <v-btn
                small
                dark
                color="#26af82"
                text
                block
                class="text-capitalize"
                @click="addBlog"
              >Publish Blog Post</v-btn>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import { VueEditor } from "vue2-editor";
export default {
  components: {
    VueEditor
  },
  data() {
    return {
      appLoading: false,
      showSnackBar: false,
      timeout: 3000,
      msg: null,
      title: null,
      shortDescription: null,
      description: null,
      author: null,
      tag: [],
      tags: ["Environment", "Science", "Technology"],
      category: [],
      categories: [],
      type: "Normal Blog",
      types: ["Normal Blog", "Video Blog", "Document Blog"],
      media: null,
      thumbnail: null,
      mediaURL: null,
      draft: false,
      blogId: null
    };
  },
  beforeMount() {
    this.getCategory();
  },
  methods: {
    getCategory() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/project/category/getlist"
      })
        .then(response => {
          this.categories = response.data.data;
          this.appLoading = false;
        })
        .catch(err => {
          this.appLoading = false;
          this.msg = err;
          this.msg = "Server Unreachable";
          this.showSnackBar = true;
        });
    },
    onFileChanged(event) {
      if (event.target.files[0].size > 10485760) {
        this.msg = "File Should be less than 10MB";
        this.showSnackBar = true;
        document.getElementById("media").value = "";
        return;
      } else {
        this.media = event.target.files[0];
        // this.mediaURL = URL.createObjectURL(this.media);
      }
    },
    onFileChanged2(event) {
      if (event.target.files[0].size > 10485760) {
        this.msg = "File Should be less than 10MB";
        this.showSnackBar = true;
        document.getElementById("thumbnail").value = "";
        return;
      } else {
        this.thumbnail = event.target.files[0];
        // this.mediaURL = URL.createObjectURL(this.media);
      }
    },
    addDraft() {
      this.draft = true;
      this.addBlog();
    },
    addBlog() {
      this.appLoading = true;
      var data = {};
      data["title"] = this.title;
      data["shortDescription"] = this.shortDescription;
      data["body"] = this.description;
      data["tag"] = this.tag;
      data["category"] = this.category;
      data["author"] = this.author;
      data["type"] = this.type;
      data["draft"] = this.draft;
      axios({
        method: "POST",
        url: "/blog/add",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        },
        data: data
      })
        .then(response => {
          this.blogId = response.data.id;
          this.msg = response.data.msg;
          this.showSnackBar = true;
          if (this.media) this.addMedia();
          this.appLoading = false;
          // this.title = null;
          // this.shortDescription = null;
          // this.description = null;
          // this.tag = [];
          // this.category = [];
          // this.author = null;
          // this.type = null;
          // this.draft = false;
          // this.media = null;
          // this.mediaURL = null;
        })
        .catch(err => {
          this.appLoading = false;
          this.msg = err;
          this.msg = "Server Unreachable";
          this.showSnackBar = true;
        });
    },
    addMedia() {
      var mediaURL = null;
      let formData = new FormData();
      formData.append("media", this.media);
      formData.append("id", this.blogId);
      if (this.type == "Video Blog") {
        mediaURL = "/blog/video";
        formData.append("thumbnail", this.thumbnail);
      } else if (this.type == "Normal Blog") {
        mediaURL = "/blog/image";
      } else if (this.type == "Document Blog") {
        mediaURL = "/blog/document";
        formData.append("thumbnail", this.thumbnail);
      }
      this.appLoading = true;
      axios({
        method: "POST",
        url: mediaURL,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token")
        },
        data: formData
      })
        .then(response => {
          this.appLoading = false;
          this.msg = response.data.msg;
          this.showSnackBar = true;
        })
        .catch(err => {
          this.appLoading = false;
          this.ms = err.response;
          this.msg = "Server Unreachable";
          this.showSnackBar = true;
        });
    }
  }
};
</script>
<style scoped>
.v-application .primary--text {
  color: #26af82 !important;
  caret-color: #26af82 !important;
}
</style>